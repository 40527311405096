{
  "timestamp": "2022-06-06T15:08:20.376Z",
  "centers": [
    {
      "name": "650 Pelham Parkway",
      "address": [
        "650 Pelham Parkway",
        "Bronx, NY10462"
      ],
      "coordinates": {
        "lat": 40.8564802,
        "lng": -73.8679069
      },
      "context": [
        "Monday – Sunday, 8:00AM-6:00PM",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Grand Concourse & E 182nd St",
      "address": [
        "198 E 182nd St",
        "Bronx, NY10453"
      ],
      "coordinates": {
        "lat": 40.8561044,
        "lng": -73.9009516
      },
      "context": [
        "Monday – Sunday, 8:00AM-6:00PM",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Allerton Playground",
      "address": [
        "2732 Bouck Ave",
        "Bronx, NY10469"
      ],
      "coordinates": {
        "lat": 40.8660735,
        "lng": -73.85055059999999
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Bryan Park",
      "address": [
        "311 E Fordham Rd",
        "Bronx, NY10458"
      ],
      "coordinates": {
        "lat": 40.8621674,
        "lng": -73.8941597
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "CO-OP City",
      "address": [
        "135 Einstein Loop N",
        "Bronx, NY10475"
      ],
      "coordinates": {
        "lat": 40.8643855,
        "lng": -73.8224035
      },
      "context": [
        "Wednesday – Thursday, 8am-7pm",
        "Dates: June 8 – 9",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "CO-OP City",
      "address": [
        "177 Dreiser Loop",
        "Bronx, NY10475"
      ],
      "coordinates": {
        "lat": 40.876579,
        "lng": -73.8287211
      },
      "context": [
        "Monday – Tuesday, 8am-7pm",
        "Dates: June 6 – 7",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "CO-OP City",
      "address": [
        "2049 Bartow Ave",
        "Bronx, NY10475"
      ],
      "coordinates": {
        "lat": 40.8705404,
        "lng": -73.8289394
      },
      "context": [
        "Friday – Sunday, 8am-7pm",
        "Dates: June 10 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Entrance to Crotona Pool//Adjacent to Bathgate Playground",
      "address": [
        "1700 Fulton Ave",
        "Bronx, NY10457"
      ],
      "coordinates": {
        "lat": 40.8405043,
        "lng": -73.89767739999999
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Gun Hill Playground",
      "address": [
        "736 Magenta St",
        "Bronx, NY10467"
      ],
      "coordinates": {
        "lat": 40.8746633,
        "lng": -73.8653245
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Haviland Playground",
      "address": [
        "1935 Watson Ave",
        "Bronx, NY10472"
      ],
      "coordinates": {
        "lat": 40.8291414,
        "lng": -73.8586301
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Health fair with AICC- and LACOA",
      "address": [
        "3510 Holland Ave",
        "Bronx, NY10467"
      ],
      "coordinates": {
        "lat": 40.8771092,
        "lng": -73.8646165
      },
      "context": [
        "Saturday, 10am-6pm",
        "Dates: June 11",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Magenta Playground",
      "address": [
        "3330 Olinville Ave",
        "Bronx, NY10467"
      ],
      "coordinates": {
        "lat": 40.8737543,
        "lng": -73.8679092
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "NYC Health + Hospitals North Central Bronx",
      "address": [
        "3424 Kossuth Ave",
        "Bronx, NY10467"
      ],
      "coordinates": {
        "lat": 40.8805787,
        "lng": -73.88108249999999
      },
      "context": [
        "Monday – Sunday, 8am-6pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "NYCHA Pelham Parkway",
      "address": [
        "920 Mace Ave",
        "Bronx, NY10469"
      ],
      "coordinates": {
        "lat": 40.862937,
        "lng": -73.8611073
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "PS 340",
      "address": [
        "25 W 195th St",
        "Bronx, NY10468"
      ],
      "coordinates": {
        "lat": 40.8693628,
        "lng": -73.89711129999999
      },
      "context": [
        "Tuesday, 8am-2pm",
        "Dates: June 7",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "R.A.I.N. Gunhill Neighborhood Senior Center",
      "address": [
        "3377 White Plains Rd",
        "Bronx, NY10469"
      ],
      "coordinates": {
        "lat": 40.8747134,
        "lng": -73.8673211
      },
      "context": [
        "Friday, 9am-5pm",
        "Dates: June 10",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Soundview Park",
      "address": [
        "898 Metcalf Ave",
        "Bronx, NY10473"
      ],
      "coordinates": {
        "lat": 40.8225713,
        "lng": -73.8710277
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "St. James Park",
      "address": [
        "2550 Jerome Ave",
        "Bronx, NY10468"
      ],
      "coordinates": {
        "lat": 40.86502369999999,
        "lng": -73.89777269999999
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "St. Mary’s Playground",
      "address": [
        "25 Jackson Ave",
        "Bronx, NY10454"
      ],
      "coordinates": {
        "lat": 40.8094332,
        "lng": -73.9125335
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Stars and Stripes Playground",
      "address": [
        "2051 Crawford Ave",
        "Bronx, NY10466"
      ],
      "coordinates": {
        "lat": 40.8852036,
        "lng": -73.83968999999999
      },
      "context": [
        "Monday – Sunday, 8am-6pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Tiffany Playground",
      "address": [
        "1050 Tiffany St",
        "Bronx, NY10459"
      ],
      "coordinates": {
        "lat": 40.8247736,
        "lng": -73.8943702
      },
      "context": [
        "Monday – Wednesday, 8am-6pm",
        "Dates: June 6 – 8",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Virginia Playground",
      "address": [
        "1880 McGraw Ave",
        "Bronx, NY10462"
      ],
      "coordinates": {
        "lat": 40.8343242,
        "lng": -73.8620456
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Walton Educational School",
      "address": [
        "2780 Reservoir Ave",
        "Bronx, NY10468"
      ],
      "coordinates": {
        "lat": 40.8706673,
        "lng": -73.897646
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "NYC Health + Hospitals/Gotham Health, Belvis",
      "address": [
        "545 East 142nd Street",
        "Bronx, New York 10454"
      ],
      "coordinates": {
        "lat": 40.8101401,
        "lng": -73.9157383
      },
      "context": [
        "Monday – Friday, 7 a.m. – 4 p.m.",
        "844-NYC-4NYC",
        "PCR Testing Offered Here",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "NYC Health + Hospitals/Jacobi",
      "address": [
        "1400 Pelham Parkway South",
        "Bronx, New York 10461"
      ],
      "coordinates": {
        "lat": 40.8557535,
        "lng": -73.8464133
      },
      "context": [
        "Monday – Friday, 7 a.m. – 4 p.m. (last patient taken at 3:30 p.m.)",
        "Saturday, 8 a.m. – 1 p.m. (last patient taken at 12:30 p.m.)",
        "718-918-5000",
        "PCR Testing Offered Here",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "NYC Health + Hospitals/Lincoln",
      "address": [
        "234 East 149th Street",
        "Bronx, New York 10451"
      ],
      "coordinates": {
        "lat": 40.816112,
        "lng": -73.9247757
      },
      "context": [
        "Monday – Friday, 9 a.m. – 5 p.m.",
        "718-579-5000",
        "PCR Testing Offered Here",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "NYC Health + Hospitals/Gotham Health, Morrisania",
      "address": [
        "1225 Gerard Avenue",
        "Bronx, New York 10452"
      ],
      "coordinates": {
        "lat": 40.8362867,
        "lng": -73.9202294
      },
      "context": [
        "Monday – Friday, 7 a.m. – 4 p.m.",
        "844-NYC-4NYC",
        "PCR Testing Offered Here",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "NYC Health + Hospitals/North Central Bronx",
      "address": [
        "3424 Kossuth Avenue",
        "Bronx, New York 10467"
      ],
      "coordinates": {
        "lat": 40.8805787,
        "lng": -73.88108249999999
      },
      "context": [
        "Appointments:",
        "Monday – Thursday, Sunday, 7 a.m. – 7 p.m.",
        "Friday, 7 a.m. – 3 p.m.",
        "This location is temporarily closed for all COVID testing until further notice",
        "844-692-4692 General Information: 718-918-5700",
        "PCR Testing Offered Here",
        "Pre-register for your visit​"
      ]
    },
    {
      "name": "NYC Health + Hospitals/Gotham Health, Tremont",
      "address": [
        "1920 Webster Avenue,",
        "Bronx, New York 10457"
      ],
      "coordinates": {
        "lat": 40.847961,
        "lng": -73.90028
      },
      "context": [
        "Appointments:",
        "Monday – Friday, 7 a.m. – 4 p.m.",
        "PCR Testing Offered Here"
      ]
    },
    {
      "name": "Allerton Library",
      "address": [
        "2740 Barnes Avenue",
        "Bronx, NY 10467"
      ],
      "coordinates": {
        "lat": 40.866794,
        "lng": -73.863198
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Baychester Library",
      "address": [
        "2049 Asch Loop North",
        "Bronx, NY 10475"
      ],
      "coordinates": {
        "lat": 40.8708616,
        "lng": -73.8291754
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Belmont Library",
      "address": [
        "610 East 186th Street",
        "Bronx, NY 10458"
      ],
      "coordinates": {
        "lat": 40.8543992,
        "lng": -73.8878934
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Bronx House NORC Senior Center",
      "address": [
        "2425 Williamsbridge Rd",
        "Bronx, NY 10469"
      ],
      "coordinates": {
        "lat": 40.861663,
        "lng": -73.858536
      },
      "context": [
        "Tuesday, Thursday-Friday, 9am-5pm",
        "Dates: May 17, 19 – 20",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Bronx Museum",
      "address": [
        "1040 Grand Concourse",
        "Bronx, NY 10456"
      ],
      "coordinates": {
        "lat": 40.8311761,
        "lng": -73.91970239999999
      },
      "context": [
        "Tuesday, 1pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Bronx Library Center",
      "address": [
        "310 East Kingsbridge Road",
        "Bronx, NY 10458"
      ],
      "coordinates": {
        "lat": 40.8630503,
        "lng": -73.89439399999999
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Castle Hill Library",
      "address": [
        "947 Castle Hill Avenue",
        "Bronx, NY 10473"
      ],
      "coordinates": {
        "lat": 40.8265756,
        "lng": -73.8501306
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "City Island Library",
      "address": [
        "320 City Island Avenue",
        "Bronx, NY 10464"
      ],
      "coordinates": {
        "lat": 40.8477849,
        "lng": -73.7864412
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Eastchester Library",
      "address": [
        "1385 East Gun Hill Road",
        "Bronx, NY 10469"
      ],
      "coordinates": {
        "lat": 40.870128,
        "lng": -73.84501399999999
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Francis Martin Library",
      "address": [
        "2150 University Avenue",
        "Bronx, NY 10453"
      ],
      "coordinates": {
        "lat": 40.857522,
        "lng": -73.90926530000002
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "High Bridge Library",
      "address": [
        "78 West 168th Street",
        "Bronx, NY 10452"
      ],
      "coordinates": {
        "lat": 40.8379912,
        "lng": -73.9235362
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Jerome Park Library",
      "address": [
        "118 Eames Place",
        "Bronx, NY 10468"
      ],
      "coordinates": {
        "lat": 40.8690733,
        "lng": -73.9012212
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Morris Park Library",
      "address": [
        "985 Morris Park Avenue",
        "Bronx, NY 10462"
      ],
      "coordinates": {
        "lat": 40.8480268,
        "lng": -73.8568162
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Parkchester Library",
      "address": [
        "1985 Westchester Avenue",
        "Bronx, NY 10462"
      ],
      "coordinates": {
        "lat": 40.8337549,
        "lng": -73.858364
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Pelham Bay Library",
      "address": [
        "3060 Middletown Road",
        "Bronx, NY 10461"
      ],
      "coordinates": {
        "lat": 40.8443222,
        "lng": -73.8280487
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Pelham Parkway-Van Nest Library",
      "address": [
        "2147 Barnes Avenue",
        "Bronx, NY 10462"
      ],
      "coordinates": {
        "lat": 40.8548601,
        "lng": -73.86418909999999
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Riverdale Library",
      "address": [
        "5540 Mosholu Avenue",
        "Bronx, NY 10471"
      ],
      "coordinates": {
        "lat": 40.9037483,
        "lng": -73.9028093
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Sedgwick Library",
      "address": [
        "1701 Dr. Martin Luther King Jr. Boulevard",
        "Bronx, NY 10453"
      ],
      "coordinates": {
        "lat": 40.8494904,
        "lng": -73.9175686
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Soundview Library",
      "address": [
        "660 Soundview Avenue",
        "Bronx, NY 10473"
      ],
      "coordinates": {
        "lat": 40.8187571,
        "lng": -73.86293479999999
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Spuyten Duyvil Library",
      "address": [
        "650 West 235th Street",
        "Bronx, NY 10463"
      ],
      "coordinates": {
        "lat": 40.8863206,
        "lng": -73.9146524
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Throgs Neck Library",
      "address": [
        "3025 Cross Bronx Expressway Extension",
        "Bronx, NY 10465"
      ],
      "coordinates": {
        "lat": 40.8228812,
        "lng": -73.8191516
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Tremont Library",
      "address": [
        "1866 Washington Avenue",
        "Bronx, NY 10457"
      ],
      "coordinates": {
        "lat": 40.846042,
        "lng": -73.89833999999999
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Van Cortlandt Library",
      "address": [
        "3882 Cannon Place",
        "Bronx, NY 10463"
      ],
      "coordinates": {
        "lat": 40.883563,
        "lng": -73.894465
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Wave Hill",
      "address": [
        "4900 Independence Avenue",
        "Bronx, NY 10471"
      ],
      "coordinates": {
        "lat": 40.8978516,
        "lng": -73.91144489999999
      },
      "context": [
        "Thursday, 10am-12pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Westchester Square Library",
      "address": [
        "2521 Glebe Place",
        "Bronx, NY 10461"
      ],
      "coordinates": {
        "lat": 40.840884,
        "lng": -73.84667019999999
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Woodlawn Heights Library",
      "address": [
        "4355 Katonah Avenue",
        "Bronx, NY 10470"
      ],
      "coordinates": {
        "lat": 40.9004413,
        "lng": -73.8679563
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "West Farms Library",
      "address": [
        "2085 Honeywell Avenue",
        "Bronx, NY 10460"
      ],
      "coordinates": {
        "lat": 40.8446909,
        "lng": -73.8830286
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Richmondtown Library",
      "address": [
        "200 Clarke Avenue",
        "Bronx, NY 10306"
      ],
      "coordinates": {
        "lat": 40.5665074,
        "lng": -74.1384473
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Grand Concourse Library",
      "address": [
        "155 East 173rd Street",
        "Bronx, NY 10457"
      ],
      "coordinates": {
        "lat": 40.843997,
        "lng": -73.910071
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Kingsbridge Library",
      "address": [
        "291 West 231st Street",
        "Bronx, NY 10463"
      ],
      "coordinates": {
        "lat": 40.8807532,
        "lng": -73.9076722
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Mosholu Library",
      "address": [
        "285 East 205th Street",
        "Bronx, NY 10467"
      ],
      "coordinates": {
        "lat": 40.8743799,
        "lng": -73.8784304
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Clason’s Point Library",
      "address": [
        "1215 Morrison Avenue",
        "Bronx, NY 10472"
      ],
      "coordinates": {
        "lat": 40.8299611,
        "lng": -73.87484560000001
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Edenwald Library",
      "address": [
        "1255 East 233rd Street",
        "Bronx, NY 10466"
      ],
      "coordinates": {
        "lat": 40.8888845,
        "lng": -73.8408546
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Mott Haven Library",
      "address": [
        "321 East 140th Street",
        "Bronx, NY 10454"
      ],
      "coordinates": {
        "lat": 40.8115937,
        "lng": -73.92436289999999
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Wakefield Library",
      "address": [
        "4100 Lowerre Place",
        "Bronx, NY 10466"
      ],
      "coordinates": {
        "lat": 40.891084,
        "lng": -73.85949099999999
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Woodstock Library",
      "address": [
        "761 East 160th Street",
        "Bronx, NY 10456"
      ],
      "coordinates": {
        "lat": 40.8206481,
        "lng": -73.9046132
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Brighton Beach Ave & Brighton 11th St",
      "address": [
        "1021 Brighton Beach Ave",
        "Brooklyn, NY11235"
      ],
      "coordinates": {
        "lat": 40.5782251,
        "lng": -73.95871869999999
      },
      "context": [
        "Monday – Sunday, 8:00AM-6:00PM",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Flatbush & Church",
      "address": [
        "866 Flatbush Avenue",
        "Brooklyn, NY11226"
      ],
      "coordinates": {
        "lat": 40.630641,
        "lng": -73.94535479999999
      },
      "context": [
        "Monday – Sunday, 8:00AM-6:00PM",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Flatbush & Nostrand",
      "address": [
        "1565 Flatbush Ave",
        "Brooklyn, NY11210"
      ],
      "coordinates": {
        "lat": 40.6322616,
        "lng": -73.9474322
      },
      "context": [
        "Monday – Sunday, 8:00AM-6:00PM",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Fulton Park",
      "address": [
        "70 Chauncey St.",
        "Brooklyn, NY11233"
      ],
      "coordinates": {
        "lat": 40.6797339,
        "lng": -73.9328673
      },
      "context": [
        "Monday – Sunday, 8:00AM-6:00PM",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Kosciuszko St & Broadway",
      "address": [
        "616 Kosciuszko St",
        "Brooklyn, NY11221"
      ],
      "coordinates": {
        "lat": 40.6918106,
        "lng": -73.9409927
      },
      "context": [
        "Monday – Sunday, 8:00AM-6:00PM",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Nostrand & Fulton",
      "address": [
        "1274 Fulton St",
        "Brooklyn, NY11216"
      ],
      "coordinates": {
        "lat": 40.6802346,
        "lng": -73.9498922
      },
      "context": [
        "Monday – Sunday, 8:00AM-6:00PM",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Rockaway Parkway & Seaview Ave",
      "address": [
        "2020 Rockaway Parkway",
        "Brooklyn, NY11236"
      ],
      "coordinates": {
        "lat": 40.645567,
        "lng": -73.90256409999999
      },
      "context": [
        "Monday – Sunday, 8:00AM-6:00PM",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Seth Low Playground",
      "address": [
        "7323 Bay Parkway",
        "Brooklyn, NY11204"
      ],
      "coordinates": {
        "lat": 40.6081073,
        "lng": -73.9863087
      },
      "context": [
        "Monday – Sunday, 8:00AM-6:00PM",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Seth Low Playground",
      "address": [
        "7323 Bay Parkway",
        "Brooklyn, NY11204"
      ],
      "coordinates": {
        "lat": 40.6081073,
        "lng": -73.9863087
      },
      "context": [
        "Monday – Sunday, 8:00AM-6:00PM",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Utica and Church",
      "address": [
        "864 Utica Ave",
        "Brooklyn, NY11203"
      ],
      "coordinates": {
        "lat": 40.6520939,
        "lng": -73.9306893
      },
      "context": [
        "Monday – Sunday, 8:00AM-6:00PM",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "100% Playground",
      "address": [
        "10002 Glenwood Rd",
        "Brooklyn, NY11236"
      ],
      "coordinates": {
        "lat": 40.6466193,
        "lng": -73.8993344
      },
      "context": [
        "Saturday – Sunday, 8am-6pm",
        "Dates: June 11 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "213 Johson Avenue (Black Health)",
      "address": [
        "213 Johnson Ave",
        "Brooklyn, NY11206"
      ],
      "coordinates": {
        "lat": 40.7069468,
        "lng": -73.94119789999999
      },
      "context": [
        "Wednesday, 8am-6pm",
        "Dates: June 8",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Arab-American Association of NY",
      "address": [
        "7111 5th Ave",
        "Brooklyn, NY11209"
      ],
      "coordinates": {
        "lat": 40.6321821,
        "lng": -74.0212403
      },
      "context": [
        "Monday, 10am-5pm",
        "Dates: June 6",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Beacon Health Fair",
      "address": [
        "101 Park Ave",
        "Brooklyn, NY11205"
      ],
      "coordinates": {
        "lat": 40.6967968,
        "lng": -73.9760706
      },
      "context": [
        "Saturday, 10AM – 5PM",
        "Dates: June 11",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Brooklyn Navy Yard",
      "address": [
        "63 Flushing Ave (Building 6)",
        "Brooklyn, NY11205"
      ],
      "coordinates": {
        "lat": 40.6992626,
        "lng": -73.969405
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Brooklyn Neighborhood Health Action Center",
      "address": [
        "485 Throop Ave",
        "Brooklyn, NY11221"
      ],
      "coordinates": {
        "lat": 40.6855549,
        "lng": -73.9411715
      },
      "context": [
        "Monday – Tuesday, Thursday, 8am-6pm,",
        "Dates: June 6 – 7",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Canarsie High School",
      "address": [
        "1600 Rockaway Parkway",
        "Brooklyn, NY11236"
      ],
      "coordinates": {
        "lat": 40.640443,
        "lng": -73.8987798
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Caribbean Women’s Health Association",
      "address": [
        "3512 Church Ave",
        "Brooklyn, NY11203"
      ],
      "coordinates": {
        "lat": 40.6508948,
        "lng": -73.9443297
      },
      "context": [
        "Wednesday, 11am-5pm",
        "Dates: June 8",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Central Library (BPL)",
      "address": [
        "10 Grand Army Plaza",
        "Brooklyn, NY11238"
      ],
      "coordinates": {
        "lat": 40.67251110000001,
        "lng": -73.9682
      },
      "context": [
        "Saturday – Sunday, 8am-6pm",
        "Dates: June 11 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Family Services Network of New York Continuous Access Center (FSNNY)",
      "address": [
        "1721 Pitkin Ave",
        "Brooklyn, NY11212"
      ],
      "coordinates": {
        "lat": 40.6699749,
        "lng": -73.90963980000001
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Herbert Von King Park",
      "address": [
        "670 Lafayette Ave",
        "Brooklyn, NY11216"
      ],
      "coordinates": {
        "lat": 40.689612,
        "lng": -73.946732
      },
      "context": [
        "Monday – Sunday, 8am-6pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Hope Christian Church",
      "address": [
        "369 New Lots Ave",
        "Brooklyn, NY11207"
      ],
      "coordinates": {
        "lat": 40.66185979999999,
        "lng": -73.8936794
      },
      "context": [
        "Thursday, 8am-7pm",
        "Dates: June 9",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Make the Road NY – Brooklyn office",
      "address": [
        "301 Grove Street",
        "Brooklyn, NY11237"
      ],
      "coordinates": {
        "lat": 40.6984668,
        "lng": -73.9158805
      },
      "context": [
        "Friday, 12pm-6pm",
        "Dates: June 10",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Manhattan Beach Park",
      "address": [
        "698 Oriental Ave",
        "Brooklyn, NY11235"
      ],
      "coordinates": {
        "lat": 40.5768049,
        "lng": -73.942583
      },
      "context": [
        "Saturday – Sunday, 8am-6pm",
        "Dates: June 11 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Marcy Plaza",
      "address": [
        "1385 Fulton Avenue",
        "Brooklyn, NY11216"
      ],
      "coordinates": {
        "lat": 40.6804364,
        "lng": -73.9459947
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "NYCHA Roosevelt II Community/Maria Lawton Senior center",
      "address": [
        "400 Hart St",
        "Brooklyn, NY11206"
      ],
      "coordinates": {
        "lat": 40.6944294,
        "lng": -73.93466149999999
      },
      "context": [
        "Monday – Sunday, 8am-6pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "NYCHA Vandalia Ave",
      "address": [
        "20 Vandalia Ave",
        "Brooklyn, NY11239"
      ],
      "coordinates": {
        "lat": 40.6491303,
        "lng": -73.8867909
      },
      "context": [
        "Monday – Sunday, 8am-6pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "New Hope Family Worship Center",
      "address": [
        "817 Livonia Avenue",
        "Brooklyn, NY11207"
      ],
      "coordinates": {
        "lat": 40.6660724,
        "lng": -73.8860973
      },
      "context": [
        "Wednesday – Friday, 8am-7pm",
        "Dates: June 8 – 10",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Red Hook Initiative",
      "address": [
        "767 Hicks St",
        "Brooklyn, NY11231"
      ],
      "coordinates": {
        "lat": 40.6767619,
        "lng": -74.0045296
      },
      "context": [
        "Thursday, 8am-7pm",
        "Dates: June 9",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Red Hook Park",
      "address": [
        "159 Bay Street",
        "Brooklyn, NY11231"
      ],
      "coordinates": {
        "lat": 40.6721595,
        "lng": -74.0072107
      },
      "context": [
        "Wednesday – Friday, 8am-7pm",
        "Dates: June 10",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "SOLA Saturdays",
      "address": [
        "371 Lewis Ave",
        "Brooklyn, NY11233"
      ],
      "coordinates": {
        "lat": 40.6828334,
        "lng": -73.93478139999999
      },
      "context": [
        "Saturday, 11AM – 6PM",
        "Dates: June 11",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Saratoga Senior Center",
      "address": [
        "930 Halsey Street",
        "Brooklyn, NY11233"
      ],
      "coordinates": {
        "lat": 40.685109,
        "lng": -73.91715219999999
      },
      "context": [
        "Monday – Tuesday, 9am-5pm",
        "Dates: June 6 – 7",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "St John’s Park",
      "address": [
        "1472 Bergen St",
        "Brooklyn, NY11213"
      ],
      "coordinates": {
        "lat": 40.6750822,
        "lng": -73.9350745
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Star Spangled Playground",
      "address": [
        "200 Franklin Ave",
        "Brooklyn, NY11205"
      ],
      "coordinates": {
        "lat": 40.6917923,
        "lng": -73.95773249999999
      },
      "context": [
        "Monday – Sunday, 8am-6pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Sunset Park",
      "address": [
        "4200 7th Ave",
        "Brooklyn, NY11232"
      ],
      "coordinates": {
        "lat": 40.6469047,
        "lng": -74.0021478
      },
      "context": [
        "Monday – Sunday, 8am-6pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "TAMA Sundays on Tompkins Ave",
      "address": [
        "410 Tompkins Ave",
        "Brooklyn, NY11216"
      ],
      "coordinates": {
        "lat": 40.6830871,
        "lng": -73.9440753
      },
      "context": [
        "Sunday, 11AM-6PM",
        "Dates: June 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Williamsburg HRA Center",
      "address": [
        "2 George St",
        "Brooklyn, NY11206"
      ],
      "coordinates": {
        "lat": 40.7001729,
        "lng": -73.9325233
      },
      "context": [
        "Monday – Friday, 8AM – 6PM",
        "Dates: June 6 – 10",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Wilson Playground",
      "address": [
        "9915 Avenue K",
        "Brooklyn, NY11236"
      ],
      "coordinates": {
        "lat": 40.6419303,
        "lng": -73.8948691
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Wilson Playground",
      "address": [
        "9915 Avenue K",
        "Brooklyn, NY11236"
      ],
      "coordinates": {
        "lat": 40.6419303,
        "lng": -73.8948691
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "NYC Health + Hospitals/Coney Island",
      "address": [
        "2601 Ocean Parkway",
        "Brooklyn, New York 11235"
      ],
      "coordinates": {
        "lat": 40.5855566,
        "lng": -73.9648753
      },
      "context": [
        "Monday – Thursday, 8 a.m. – 4 p.m.",
        "Friday, 8 a.m. – 12 p.m.",
        "Saturday, 8 a.m. – 4 p.m.",
        "Please schedule an appointment",
        "Limited walk ins availability",
        "No walk-ins after 5 p.m. Mon – Fri and no walk-ins after 2p.m. on Saturdays",
        "*Coney Island Hospital does not offer rapid testing.",
        "844-NYC-4NYC",
        "PCR Testing Offered Here",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "NYC Health + Hospitals/Gotham Health, Cumberland",
      "address": [
        "100 North Portland Avenue",
        "Brooklyn, New York 11205"
      ],
      "coordinates": {
        "lat": 40.695099,
        "lng": -73.9764879
      },
      "context": [
        "Monday – Friday, 7 a.m. – 4 p.m.",
        "844-NYC-4NYC",
        "PCR Testing Offered Here",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "NYC Health + Hospitals/Gotham Health, East New York",
      "address": [
        "2094 Pitkin Avenue",
        "Brooklyn, New York 11207"
      ],
      "coordinates": {
        "lat": 40.671746,
        "lng": -73.8952429
      },
      "context": [
        "Monday – Friday, 7 a.m. – 4 p.m.",
        "844-NYC-4NYC",
        "PCR Testing Offered Here",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "NYC Health + Hospitals/Kings County",
      "address": [
        "T-Building, Room T-110 1st floor (Corner of Clarkson Avenue & New York Avenue)",
        "451 Clarkson Avenue"
      ],
      "coordinates": {
        "lat": 40.6568816,
        "lng": -73.94470749999999
      },
      "context": [
        "Brooklyn, New York 11203",
        "Monday – Friday, 7 a.m. – 4 p.m.",
        "718-245-3131",
        "PCR Testing Offered Here",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "NYC Health + Hospitals/Woodhull",
      "address": [
        "760 Broadway",
        "Brooklyn, New York 11206"
      ],
      "coordinates": {
        "lat": 40.6994152,
        "lng": -73.9430003
      },
      "context": [
        "Monday – Saturday, 7 a.m. – 4 p.m.",
        "Sunday, Closed",
        "718-963-8000",
        "PCR Testing Offered Here",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "4002 Fort Hamilton",
      "address": [
        "4002 Fort Hamilton Parkway",
        "Brooklyn, NY 11218"
      ],
      "coordinates": {
        "lat": 40.6424751,
        "lng": -73.99195089999999
      },
      "context": [
        "Monday – Sunday, 9 a.m. – 7 p.m.",
        "Rapid Molecular Testing Available Here",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "Bay Ridge",
      "address": [
        "5th Ave 8511 & 8515 5th Avenue",
        "Brooklyn, NY 11209"
      ],
      "coordinates": {
        "lat": 40.6215669,
        "lng": -74.0259145
      },
      "context": [
        "Monday – Sunday, 9 a.m. – 7 p.m.",
        "Rapid Molecular Testing Available Here",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "Bensonhurst",
      "address": [
        "6315 14th Avenue",
        "Brooklyn, NY 11219"
      ],
      "coordinates": {
        "lat": 40.625407,
        "lng": -73.9979369
      },
      "context": [
        "Monday – Sunday, 9 a.m. – 7 p.m.",
        "Rapid Molecular Testing Available Here",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "Midwood Pre-K",
      "address": [
        "1223 Coney Island Avenue",
        "Brooklyn, NY 11230"
      ],
      "coordinates": {
        "lat": 40.6277129,
        "lng": -73.9655327
      },
      "context": [
        "Monday – Friday, 7am – 4pm",
        "844-NYC-4NYC",
        "Rapid Molecular Testing Available Here",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "Starrett City",
      "address": [
        "1279 Pennsylvania Ave",
        "Brooklyn, NY 11239"
      ],
      "coordinates": {
        "lat": 40.64963770000001,
        "lng": -73.8830158
      },
      "context": [
        "Monday – Sunday, 9 a.m – 7 p.m.",
        "Rapid Molecular Testing Available Here",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "Adams Street Library",
      "address": [
        "9 Adams Street",
        "Brooklyn, NY 11201"
      ],
      "coordinates": {
        "lat": 40.7040753,
        "lng": -73.9883644
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Arlington Library",
      "address": [
        "203 Arlington Avenue",
        "Brooklyn, NY 11207"
      ],
      "coordinates": {
        "lat": 40.6806631,
        "lng": -73.8873421
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Bay Ridge Library",
      "address": [
        "7223 Ridge Boulevard",
        "Brooklyn, NY 11209"
      ],
      "coordinates": {
        "lat": 40.6336068,
        "lng": -74.02950729999999
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Borough Park Library",
      "address": [
        "1265 43rd Street",
        "Brooklyn, NY 11219"
      ],
      "coordinates": {
        "lat": 40.6388562,
        "lng": -73.9890175
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "Sun: 1pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Brooklyn Children’s Museum",
      "address": [
        "145 Brooklyn Avenue",
        "Brooklyn, NY 11213"
      ],
      "coordinates": {
        "lat": 40.67446109999999,
        "lng": -73.94398629999999
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "Brooklyn Heights Library",
      "address": [
        "280 Cadman Plaza West",
        "Brooklyn, NY 11201"
      ],
      "coordinates": {
        "lat": 40.6957454,
        "lng": -73.99137139999999
      },
      "context": [
        "Monday-Friday, 10am – 4pm",
        "Dates: June 8, 1pm – 4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Brooklyn Museum 200",
      "address": [
        "Eastern Parkway",
        "Brooklyn, NY 11238"
      ],
      "coordinates": {
        "lat": 40.6712062,
        "lng": -73.9636306
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "Weeksville Heritage Center",
      "address": [
        "158 Buffalo Avenue",
        "Brooklyn, NY 11213"
      ],
      "coordinates": {
        "lat": 40.67398370000001,
        "lng": -73.92522509999999
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "Brownsville Library 61",
      "address": [
        "Glenmore Avenue",
        "Brooklyn, NY 11212"
      ],
      "coordinates": {
        "lat": 40.6714475,
        "lng": -73.9085863
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "Central Library/Civic Commons",
      "address": [
        "10 Grand Army Plaza",
        "Brooklyn, NY 11238"
      ],
      "coordinates": {
        "lat": 40.67251110000001,
        "lng": -73.9682
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "Clinton Hill Library",
      "address": [
        "380 Washington Avenue",
        "Brooklyn, NY 11238"
      ],
      "coordinates": {
        "lat": 40.6874017,
        "lng": -73.9660652
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Cortelyou Library",
      "address": [
        "1305 Cortleyou Road",
        "Brooklyn, NY 11226"
      ],
      "coordinates": {
        "lat": 40.640844,
        "lng": -73.9659684
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Crown Heights Library",
      "address": [
        "560 New York Avenue",
        "Brooklyn, NY 11225"
      ],
      "coordinates": {
        "lat": 40.6611981,
        "lng": -73.9481517
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "DeKalb Library",
      "address": [
        "790 Bushwick Avenue",
        "Brooklyn, NY 11221"
      ],
      "coordinates": {
        "lat": 40.6947857,
        "lng": -73.92840439999999
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Dyker Library",
      "address": [
        "8202 13th Avenue",
        "Brooklyn, NY 11228"
      ],
      "coordinates": {
        "lat": 40.6163479,
        "lng": -74.011972
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Eastern Parkway Library",
      "address": [
        "1044 Eastern Parkway",
        "Brooklyn, NY 11213"
      ],
      "coordinates": {
        "lat": 40.6684895,
        "lng": -73.9336431
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Flatbush Library",
      "address": [
        "22 Linden Boulevard",
        "Brooklyn, NY 11226"
      ],
      "coordinates": {
        "lat": 40.651889,
        "lng": -73.9582531
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "Flatlands Library",
      "address": [
        "2065 Flatbush Avenue",
        "Brooklyn, NY 11234"
      ],
      "coordinates": {
        "lat": 40.6197853,
        "lng": -73.9331807
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Gravesend Library",
      "address": [
        "303 Avenue X",
        "Brooklyn, NY 11223"
      ],
      "coordinates": {
        "lat": 40.5906025,
        "lng": -73.9717539
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Greenpoint Library",
      "address": [
        "107 Norman Avenue",
        "Brooklyn, NY 11222"
      ],
      "coordinates": {
        "lat": 40.7260385,
        "lng": -73.9507596
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "Sun: 1pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Jamaica Bay Library",
      "address": [
        "9727 Seaview Avenue",
        "Brooklyn, NY 11236"
      ],
      "coordinates": {
        "lat": 40.6344855,
        "lng": -73.8892714
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Kensington Library",
      "address": [
        "4207 18th Avenue",
        "Brooklyn, NY 11218"
      ],
      "coordinates": {
        "lat": 40.6312818,
        "lng": -73.97542709999999
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Kings Bay Library",
      "address": [
        "3650 Nostrand Avenue",
        "Brooklyn, NY 11229"
      ],
      "coordinates": {
        "lat": 40.5947867,
        "lng": -73.9411485
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Leonard Library",
      "address": [
        "81 Devoe Street",
        "Brooklyn, NY 11211"
      ],
      "coordinates": {
        "lat": 40.7135869,
        "lng": -73.9478783
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Macon Library",
      "address": [
        "361 Lewis Avenue",
        "Brooklyn, NY 11233"
      ],
      "coordinates": {
        "lat": 40.6830369,
        "lng": -73.9348376
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "Sun: 1pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Mapleton Library",
      "address": [
        "1702 60th Street",
        "Brooklyn, NY 11204"
      ],
      "coordinates": {
        "lat": 40.6231592,
        "lng": -73.98945599999999
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "Sun: 1pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "McKinley Park Library",
      "address": [
        "6802 Fort Hamilton Parkway",
        "Brooklyn, NY 11219"
      ],
      "coordinates": {
        "lat": 40.6291783,
        "lng": -74.0118801
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Midwood Library",
      "address": [
        "975 East 16th Street",
        "Brooklyn, NY 11230"
      ],
      "coordinates": {
        "lat": 40.625907,
        "lng": -73.96040049999999
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "Sun: 1pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Mill Basin Library",
      "address": [
        "2385 Ralph Avenue",
        "Brooklyn, NY 11234"
      ],
      "coordinates": {
        "lat": 40.6199454,
        "lng": -73.91692259999999
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "New Utrecht Library",
      "address": [
        "1743 86th Street",
        "Brooklyn, NY 11214"
      ],
      "coordinates": {
        "lat": 40.6080187,
        "lng": -74.0033411
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "Paerdegat Library",
      "address": [
        "850 East 59th Street",
        "Brooklyn, NY 11234"
      ],
      "coordinates": {
        "lat": 40.6325973,
        "lng": -73.9199539
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Park Slope Library",
      "address": [
        "431 6th Avenue",
        "Brooklyn, NY 11215"
      ],
      "coordinates": {
        "lat": 40.6682186,
        "lng": -73.9834474
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "Site Temporarily Closed",
        "2 years old and above"
      ]
    },
    {
      "name": "Red Hook Library",
      "address": [
        "7 Wolcott Street",
        "Brooklyn, NY 11231"
      ],
      "coordinates": {
        "lat": 40.675278,
        "lng": -74.01031929999999
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "Rugby Library",
      "address": [
        "1000 Utica Avenue",
        "Brooklyn, NY 11203"
      ],
      "coordinates": {
        "lat": 40.6486576,
        "lng": -73.9303793
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Ryder Library",
      "address": [
        "5902 23rd Avenue",
        "Brooklyn, NY 11204"
      ],
      "coordinates": {
        "lat": 40.6159251,
        "lng": -73.9759804
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "Sun: 1pm-4pm",
        "Site Temporarily Closed",
        "2 years old and above"
      ]
    },
    {
      "name": "Sheepshead Bay Library",
      "address": [
        "2636 East 14th Street",
        "Brooklyn, NY 11235"
      ],
      "coordinates": {
        "lat": 40.5869651,
        "lng": -73.95540710000002
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Stone Avenue Library",
      "address": [
        "581 Mother Gaston Boulevard",
        "Brooklyn, NY 11212"
      ],
      "coordinates": {
        "lat": 40.6644738,
        "lng": -73.9051607
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Walt Whitman Library",
      "address": [
        "93 Saint Edwards Street",
        "Brooklyn, NY 11205"
      ],
      "coordinates": {
        "lat": 40.6944632,
        "lng": -73.97772669999999
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Washington Irving Library",
      "address": [
        "360 Irving Avenue",
        "Brooklyn, NY 11237"
      ],
      "coordinates": {
        "lat": 40.6975031,
        "lng": -73.9122378
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Windsor Terrace Library",
      "address": [
        "160 East 5th Street",
        "Brooklyn, NY 11218"
      ],
      "coordinates": {
        "lat": 40.6487074,
        "lng": -73.9767289
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Brighton Beach Library",
      "address": [
        "16 Brighton First Road",
        "Brooklyn, NY 11235"
      ],
      "coordinates": {
        "lat": 40.5761784,
        "lng": -73.9667995
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "Bushwick Library",
      "address": [
        "340 Bushwick Avenue",
        "Brooklyn, NY 11206"
      ],
      "coordinates": {
        "lat": 40.7045694,
        "lng": -73.9396101
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "Coney Island Library",
      "address": [
        "1901 Mermaid Avenue",
        "Brooklyn, NY 11224"
      ],
      "coordinates": {
        "lat": 40.5767651,
        "lng": -73.9860791
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "Canarsie Library",
      "address": [
        "1580 Rockaway Parkway",
        "Brooklyn, NY 11236"
      ],
      "coordinates": {
        "lat": 40.642143,
        "lng": -73.8996916
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "Cypress Hills Library",
      "address": [
        "1197 Sutter Avenue at Crystal Street",
        "Brooklyn, NY 11208"
      ],
      "coordinates": {
        "lat": 40.6726465,
        "lng": -73.8740892
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "Fort Hamilton Library",
      "address": [
        "9424 Fourth Avenue",
        "Brooklyn, NY 11209"
      ],
      "coordinates": {
        "lat": 40.6163806,
        "lng": -74.0313434
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "Gerritsen Library",
      "address": [
        "2808 Gerritsen Avenue",
        "Brooklyn, NY 11229"
      ],
      "coordinates": {
        "lat": 40.5913977,
        "lng": -73.9239955
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "Highlawn Library",
      "address": [
        "1664 West 13th Street",
        "Brooklyn, NY 11223"
      ],
      "coordinates": {
        "lat": 40.6056632,
        "lng": -73.9862627
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "Homecrest Library",
      "address": [
        "2525 Coney Island Avenue",
        "Brooklyn, NY 11223"
      ],
      "coordinates": {
        "lat": 40.5952034,
        "lng": -73.96055009999999
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "Kings Highway Library",
      "address": [
        "2115 Ocean Avenue",
        "Brooklyn, NY 11229"
      ],
      "coordinates": {
        "lat": 40.6102591,
        "lng": -73.95311389999999
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "New Lots Library",
      "address": [
        "665 New Lots Avenue",
        "Brooklyn, NY 11207"
      ],
      "coordinates": {
        "lat": 40.6651154,
        "lng": -73.8861478
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "Pacific Library",
      "address": [
        "25 Fourth Avenue",
        "Brooklyn, NY 11217"
      ],
      "coordinates": {
        "lat": 40.6834521,
        "lng": -73.9785522
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "Spring Creek Library",
      "address": [
        "12143 Flatlands Avenue",
        "Brooklyn, NY 11207"
      ],
      "coordinates": {
        "lat": 40.6532637,
        "lng": -73.88596410000001
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "Sunset Park Library",
      "address": [
        "4201 Fourth Avenue",
        "Brooklyn, NY 11232"
      ],
      "coordinates": {
        "lat": 40.6505082,
        "lng": -74.0079322
      },
      "context": [
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "Ulmer Park Library",
      "address": [
        "2602 Bath Avenue",
        "Brooklyn, NY 11214"
      ],
      "coordinates": {
        "lat": 40.5926124,
        "lng": -73.9886502
      },
      "context": [
        "Mon, Wed, Fri: 10am-5pm",
        "Tue: 1pm-7pm",
        "Thu: 10am-7pm",
        "Sat: 10am-4pm",
        "Monday-Saturday, during regular hours of service, up to one hour before closing",
        "2 years old and above"
      ]
    },
    {
      "name": "Williamsburg Library",
      "address": [
        "240 Division Avenue Brooklyn, NY 11211",
        "Monday-Saturday, during regular hours of service, up to one hour before closing"
      ],
      "coordinates": {
        "lat": 40.7069143,
        "lng": -73.9575883
      },
      "context": [
        "2 years old and above"
      ]
    },
    {
      "name": "Ilka Tanya Payán Park",
      "address": [
        "3771 Broadway",
        "Manhattan, NY10032"
      ],
      "coordinates": {
        "lat": 40.8340422,
        "lng": -73.94519009999999
      },
      "context": [
        "Monday – Sunday, 8:00AM-6:00PM",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "116th St Puerto Rican Festival",
      "address": [
        "205 E 116th St",
        "Manhattan, NY10029"
      ],
      "coordinates": {
        "lat": 40.797986,
        "lng": -73.93937199999999
      },
      "context": [
        "Saturday, 10am-5pm",
        "Dates: June 11",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Abyssinian Baptist Church",
      "address": [
        "132 West 138th Street",
        "Manhattan, NY10030"
      ],
      "coordinates": {
        "lat": 40.8166821,
        "lng": -73.9415561
      },
      "context": [
        "Sunday, 8am-6pm",
        "Dates: June 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Bennett Park",
      "address": [
        "521 Fort Washington Ave",
        "Manhattan, NY10033"
      ],
      "coordinates": {
        "lat": 40.8528053,
        "lng": -73.93800279999999
      },
      "context": [
        "Monday – Sunday, 8am-6pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Black Health",
      "address": [
        "215 W 125th Street",
        "Manhattan, NY10027"
      ],
      "coordinates": {
        "lat": 40.8096896,
        "lng": -73.94888639999999
      },
      "context": [
        "Thursday, 10am-5pm",
        "Dates: June 9",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Broadway Community, Inc. Soup Kitchen",
      "address": [
        "601 W 114th St",
        "Manhattan, NY10025"
      ],
      "coordinates": {
        "lat": 40.8070418,
        "lng": -73.9651341
      },
      "context": [
        "Monday, 8am-4pm",
        "Dates: June 9",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "CUNY Graduate Center",
      "address": [
        "365 5th Avenue",
        "Manhattan, NY10016"
      ],
      "coordinates": {
        "lat": 40.7486484,
        "lng": -73.98400699999999
      },
      "context": [
        "Tuesday, 8am-6pm",
        "Dates: June 7",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Columbus Park",
      "address": [
        "35 Baxter St",
        "Manhattan, NY10013"
      ],
      "coordinates": {
        "lat": 40.7155353,
        "lng": -74.0000483
      },
      "context": [
        "Monday – Sunday, 8am-6pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "First Spanish UMC",
      "address": [
        "163 E 111th street",
        "Manhattan, NY10029"
      ],
      "coordinates": {
        "lat": 40.7954716,
        "lng": -73.9435548
      },
      "context": [
        "Saturday – Sunday, 8AM – 6PM",
        "Dates: June 11 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Fred Samuel Playground",
      "address": [
        "580 Lenox Ave",
        "Manhattan, NY10030"
      ],
      "coordinates": {
        "lat": 40.8171492,
        "lng": -73.9391543
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Gotham Dyckman",
      "address": [
        "175 Nagle Ave",
        "Manhattan, NY10034"
      ],
      "coordinates": {
        "lat": 40.86140109999999,
        "lng": -73.9242916
      },
      "context": [
        "Monday – Sunday, 8am-6pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Gotham Sydenham",
      "address": [
        "264 W 118th St",
        "Manhattan, NY10026"
      ],
      "coordinates": {
        "lat": 40.8052028,
        "lng": -73.9536622
      },
      "context": [
        "Monday – Sunday, 9am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Happy Warrior Playground",
      "address": [
        "779 W 97th St",
        "Manhattan, NY10025"
      ],
      "coordinates": {
        "lat": 40.7954101,
        "lng": -73.96868979999999
      },
      "context": [
        "Monday – Tuesday, 8am-6pm",
        "Dates: June 6 – 7",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "J Hood Wright Park",
      "address": [
        "154 Haven Ave",
        "Manhattan, NY10033"
      ],
      "coordinates": {
        "lat": 40.8463679,
        "lng": -73.941493
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "LSA Family Health",
      "address": [
        "333 E 115th St",
        "Manhattan, NY10029"
      ],
      "coordinates": {
        "lat": 40.7958531,
        "lng": -73.9366021
      },
      "context": [
        "Monday – Friday, 8am-6pm",
        "Dates: June 6 – 10",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Marcus Garvey Park",
      "address": [
        "6316, Mt Morris Park W.",
        "Manhattan, NY10027"
      ],
      "coordinates": {
        "lat": 40.8044257,
        "lng": -73.9437629
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Masaryk Towers",
      "address": [
        "75 Columbia Street",
        "Manhattan, NY10002"
      ],
      "coordinates": {
        "lat": 40.717828,
        "lng": -73.9801928
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Monsignor Kett Playground",
      "address": [
        "500 W 204th St",
        "Manhattan, NY10034"
      ],
      "coordinates": {
        "lat": 40.8624611,
        "lng": -73.92099619999999
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Morningside Park",
      "address": [
        "38 Morningside Ave",
        "Manhattan, NY10026"
      ],
      "coordinates": {
        "lat": 40.8060712,
        "lng": -73.9585391
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "NYCHA Manhattanville",
      "address": [
        "549 W 126th St",
        "Manhattan, NY10027"
      ],
      "coordinates": {
        "lat": 40.8162173,
        "lng": -73.95713409999999
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "NYCHA Riis",
      "address": [
        "90 Avenue D",
        "Manhattan, NY10009"
      ],
      "coordinates": {
        "lat": 40.7229374,
        "lng": -73.97600489999999
      },
      "context": [
        "Saturday, 8am-7pm",
        "Dates: June 11",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "NYCHA Stanley Isaacs Neighborhood Center",
      "address": [
        "403 E 93rd St",
        "Manhattan, NY10128"
      ],
      "coordinates": {
        "lat": 40.7813325,
        "lng": -73.9457278
      },
      "context": [
        "Wednesday – Friday, 8am-6pm",
        "Dates: June 8 – 11",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Payson Playground",
      "address": [
        "287 Dyckman St",
        "Manhattan, NY10034"
      ],
      "coordinates": {
        "lat": 40.8671665,
        "lng": -73.9289057
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Quisqueya Playground",
      "address": [
        "2405 Amsterdam Ave",
        "Manhattan, NY10033"
      ],
      "coordinates": {
        "lat": 40.8471006,
        "lng": -73.9313288
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Sheltering Arms Playground",
      "address": [
        "479 W 126th St",
        "Manhattan, NY10027"
      ],
      "coordinates": {
        "lat": 40.8140093,
        "lng": -73.9553786
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Thomas Jefferson Park",
      "address": [
        "2180 1st Ave",
        "Manhattan, NY10029"
      ],
      "coordinates": {
        "lat": 40.7933578,
        "lng": -73.9352341
      },
      "context": [
        "Wednesday, 8am-6pm",
        "Dates: June 8",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "NYC Health + Hospitals/Bellevue",
      "address": [
        "462 First Avenue",
        "New York, New York 10016"
      ],
      "coordinates": {
        "lat": 40.7393499,
        "lng": -73.97565449999999
      },
      "context": [
        "212-562-5555",
        "Monday – Friday, Walk Ins: 7 a.m. – 4 p.m.",
        "Saturday, Sunday, CLOSED",
        "PCR Testing Offered Here",
        "*Walk-in hours maybe subject to change depending on patient volume.",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "NYC Health + Hospitals/Gotham Health, Gouverneur",
      "address": [
        "227 Madison Street",
        "New York, New York 10002"
      ],
      "coordinates": {
        "lat": 40.7129914,
        "lng": -73.98770329999999
      },
      "context": [
        "Monday – Friday, 7 a.m. – 4 p.m.",
        "844-NYC-4NYC",
        "PCR Testing Offered Here",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "NYC Health + Hospitals/Harlem",
      "address": [
        "506 Lenox Avenue",
        "New York, New York 10037"
      ],
      "coordinates": {
        "lat": 40.8143104,
        "lng": -73.93950869999999
      },
      "context": [
        "Adults",
        "Monday, Wednesday, Friday, Saturday, 8 a.m. – 4:00 p.m.",
        "Tuesday, Thursday, 7:00 a.m. – 4:00 p.m.",
        "Pediatrics",
        "Monday – Friday, 8:00 a.m. – 4:00 p.m.",
        "212-939-1000",
        "PCR Testing Offered Here",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "NYC Health + Hospitals/Metropolitan",
      "address": [
        "1901 First Avenue",
        "New York, New York 10029"
      ],
      "coordinates": {
        "lat": 40.7848889,
        "lng": -73.9443993
      },
      "context": [
        "Monday – Saturday 8:00 a.m. – 4:00 p.m.",
        "Closed: Juneteenth (6/20), and Independence Day (7/4)",
        "212-423-6262",
        "PCR Testing Offered Here",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "53rd Street Library",
      "address": [
        "18 West 53rd Street",
        "Manhattan, NY 10019"
      ],
      "coordinates": {
        "lat": 40.76085399999999,
        "lng": -73.977245
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "58thd Street Library",
      "address": [
        "127 East 58th Street",
        "Manhattan, NY 10022"
      ],
      "coordinates": {
        "lat": 40.7619724,
        "lng": -73.969306
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "67th Street Library",
      "address": [
        "328 East 67th Street",
        "Manhattan, NY 10065"
      ],
      "coordinates": {
        "lat": 40.7649134,
        "lng": -73.9595554
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "96th Street Library",
      "address": [
        "112 East 96th Street",
        "Manhattan, NY 10128"
      ],
      "coordinates": {
        "lat": 40.7859538,
        "lng": -73.951815
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "American Museum of Natural History",
      "address": [
        "200 Central Park West",
        "Manhattan, NY 10024"
      ],
      "coordinates": {
        "lat": 40.7813241,
        "lng": -73.9739882
      },
      "context": [
        "Wednesday-Sunday, 10am-5pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Battery Park City Library",
      "address": [
        "175 North End Avenue",
        "Manhattan, NY 10282"
      ],
      "coordinates": {
        "lat": 40.7157419,
        "lng": -74.01574049999999
      },
      "context": [
        "Mon-Fri: 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Bloomingdale Library",
      "address": [
        "150 West 100th Street",
        "Manhattan, NY 10025"
      ],
      "coordinates": {
        "lat": 40.795851,
        "lng": -73.9676214
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Chatham Square Library",
      "address": [
        "33 East Broadway",
        "Manhattan, NY 10002"
      ],
      "coordinates": {
        "lat": 40.7133341,
        "lng": -73.9964961
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Columbus Library",
      "address": [
        "742 Tenth Avenue",
        "Manhattan, NY 10019"
      ],
      "coordinates": {
        "lat": 40.7649496,
        "lng": -73.991226
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "El Museo del Barrio",
      "address": [
        "1230 Fifth Avenue",
        "Manhattan, NY 10029"
      ],
      "coordinates": {
        "lat": 40.7931183,
        "lng": -73.9514063
      },
      "context": [
        "Friday, 11am-3pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Epiphany Library",
      "address": [
        "228 East 23rd Street",
        "Manhattan, NY 10010"
      ],
      "coordinates": {
        "lat": 40.7380929,
        "lng": -73.9820388
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Grand Central Library",
      "address": [
        "135 East 46th Street",
        "Manhattan, NY 10017"
      ],
      "coordinates": {
        "lat": 40.7539033,
        "lng": -73.9735748
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Harlem Library",
      "address": [
        "9 West 124th Street",
        "Manhattan, NY 10027"
      ],
      "coordinates": {
        "lat": 40.806215,
        "lng": -73.943276
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Hudson Park Library",
      "address": [
        "66 Leroy Street",
        "Manhattan, NY 10014"
      ],
      "coordinates": {
        "lat": 40.7300844,
        "lng": -74.0052671
      },
      "context": [
        "Mon-Fri: 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Inwood Library",
      "address": [
        "4857 Broadway",
        "Manhattan, NY 10034"
      ],
      "coordinates": {
        "lat": 40.8670001,
        "lng": -73.92436289999999
      },
      "context": [
        "Mon-Fri: 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Macomb’s Bridge Library",
      "address": [
        "2633 Adam Clayton Powel, Jr. Boulevard",
        "Manhattan, NY 10039"
      ],
      "coordinates": {
        "lat": 40.8254622,
        "lng": -73.9359734
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Morningside Heights Library",
      "address": [
        "2900 Broadway",
        "Manhattan, NY 10025"
      ],
      "coordinates": {
        "lat": 40.8060839,
        "lng": -73.9647098
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Mulberry Street Library",
      "address": [
        "10 Jersey Street",
        "Manhattan, NY 10012"
      ],
      "coordinates": {
        "lat": 40.7241149,
        "lng": -73.99559219999999
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Museum of the City of New York",
      "address": [
        "1220 Fifth Avenue",
        "Manhattan, NY 10029"
      ],
      "coordinates": {
        "lat": 40.7924939,
        "lng": -73.95190889999999
      },
      "context": [
        "Thursday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "New Amsterdam Library",
      "address": [
        "9 Murray Street",
        "Manhattan, NY 10007"
      ],
      "coordinates": {
        "lat": 40.7136403,
        "lng": -74.00784519999999
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Ottendorfer Library",
      "address": [
        "135 Second Avenue",
        "Manhattan, NY 10003"
      ],
      "coordinates": {
        "lat": 40.7288855,
        "lng": -73.98768489999999
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Riverside Library",
      "address": [
        "127 Amsterdam Avenue",
        "Manhattan, NY 10023"
      ],
      "coordinates": {
        "lat": 40.7743645,
        "lng": -73.9845136
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "St. Agnes Library",
      "address": [
        "444 Amsterdam Avenue",
        "Manhattan, NY 10024"
      ],
      "coordinates": {
        "lat": 40.784844,
        "lng": -73.97742439999999
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Webster Library",
      "address": [
        "1465 York Avenue",
        "Manhattan, NY 10075"
      ],
      "coordinates": {
        "lat": 40.7706452,
        "lng": -73.95132389999999
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Yorkville Library",
      "address": [
        "222 East 79th Street",
        "Manhattan, NY 10075"
      ],
      "coordinates": {
        "lat": 40.7736427,
        "lng": -73.9563895
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Andrew Heiskell Braille and Talking Book Library",
      "address": [
        "40 West 20th Street #1",
        "Manhattan, NY 10011"
      ],
      "coordinates": {
        "lat": 40.7404546,
        "lng": -73.99338159999999
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Countee Cullen Library",
      "address": [
        "104 West 136th Street",
        "Manhattan, NY 10030"
      ],
      "coordinates": {
        "lat": 40.8148512,
        "lng": -73.9411318
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Washington Heights Library",
      "address": [
        "1000 St. Nicholas Avenue",
        "Manhattan, NY 10032"
      ],
      "coordinates": {
        "lat": 40.8345032,
        "lng": -73.9397097
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Seward Park Library",
      "address": [
        "192 East Broadway",
        "Manhattan, NY 10002"
      ],
      "coordinates": {
        "lat": 40.7144701,
        "lng": -73.9884896
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Aguilar Library",
      "address": [
        "174 East 110th Street",
        "Manhattan, NY 10029"
      ],
      "coordinates": {
        "lat": 40.7942325,
        "lng": -73.94343599999999
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "George Bruce Library",
      "address": [
        "518 West 125th Street",
        "Manhattan, NY 10027"
      ],
      "coordinates": {
        "lat": 40.8137896,
        "lng": -73.9569903
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Hamilton Grange Library",
      "address": [
        "503 West 145th Street",
        "Manhattan, NY 10031"
      ],
      "coordinates": {
        "lat": 40.8256848,
        "lng": -73.9480598
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Kips Bay Library",
      "address": [
        "446 Third Avenue",
        "Manhattan, NY 10016"
      ],
      "coordinates": {
        "lat": 40.7438377,
        "lng": -73.9799207
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Roosevelt Island Library",
      "address": [
        "524 Main Street",
        "Manhattan, NY 10044"
      ],
      "coordinates": {
        "lat": 40.7608318,
        "lng": -73.9507465
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Stavros Niarchos Foundation Library",
      "address": [
        "455 Fifth Avenue",
        "Manhattan, NY 10016"
      ],
      "coordinates": {
        "lat": 40.7518974,
        "lng": -73.9818374
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Tompkins Square Library",
      "address": [
        "331 East 10th Street",
        "Manhattan, NY 10009"
      ],
      "coordinates": {
        "lat": 40.72731539999999,
        "lng": -73.9803838
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Queens Plaza South",
      "address": [
        "25-14 Queens Plaza S",
        "Queens, NY11101"
      ],
      "coordinates": {
        "lat": 40.7503136,
        "lng": -73.9404311
      },
      "context": [
        "Monday – Sunday, 8:00AM-6:00PM",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "5th St & 46th Ave",
      "address": [
        "5-25 46th Ave",
        "Queens, NY11101"
      ],
      "coordinates": {
        "lat": 40.7474559,
        "lng": -73.95417239999999
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "A Better College Civic Association – Health Fair",
      "address": [
        "123-06 18th Ave",
        "Queens, NY11356"
      ],
      "coordinates": {
        "lat": 40.783094,
        "lng": -73.84476049999999
      },
      "context": [
        "Sunday, 9AM-4PM",
        "Dates: June 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Alley Pond Park",
      "address": [
        "73-06 Springfield Blvd",
        "Queens, NY11364"
      ],
      "coordinates": {
        "lat": 40.74239470000001,
        "lng": -73.73843409999999
      },
      "context": [
        "Monday – Sunday, 8am-6pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Baisley Pond South Playground",
      "address": [
        "130-4 150th St",
        "Queens, NY11436"
      ],
      "coordinates": {
        "lat": 40.6692111,
        "lng": -73.7881317
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Beach 39th",
      "address": [
        "42-12 Rockaway Beach Blvd",
        "Queens, NY11691"
      ],
      "coordinates": {
        "lat": 40.5940502,
        "lng": -73.77423859999999
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Corona Health Fair",
      "address": [
        "34-33 Junction Blvd",
        "Queens, NY11372"
      ],
      "coordinates": {
        "lat": 40.7543866,
        "lng": -73.8720555
      },
      "context": [
        "Saturday, 10am-4pm",
        "Dates: June 11",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Electric Playground",
      "address": [
        "65-40 164th St.",
        "Queens, NY11365"
      ],
      "coordinates": {
        "lat": 40.7360573,
        "lng": -73.8053296
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Episcopal Church of St. Alban the Martyr",
      "address": [
        "116-42 Farmers Blvd.",
        "Queens, NY11412"
      ],
      "coordinates": {
        "lat": 40.6926113,
        "lng": -73.7628577
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Gorman Playground",
      "address": [
        "25-02 84th St",
        "Queens, NY11435"
      ],
      "coordinates": {
        "lat": 40.7630771,
        "lng": -73.8850798
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Gotham Ridgewood",
      "address": [
        "769 Onderdonk Ave",
        "Queens, NY11385"
      ],
      "coordinates": {
        "lat": 40.7031396,
        "lng": -73.90552040000001
      },
      "context": [
        "Monday – Sunday, 8am-6pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Greater Allen Cathedral of NY",
      "address": [
        "110-31 Merrick Blvd",
        "Queens, NY11433"
      ],
      "coordinates": {
        "lat": 40.6948948,
        "lng": -73.7814918
      },
      "context": [
        "Monday – Friday, 8am-7pm, Sunday,8am-3pm",
        "Dates: June 6 – 10, 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Greenhouse Playground",
      "address": [
        "83-98 Woodhaven Blvd",
        "Queens, NY11421"
      ],
      "coordinates": {
        "lat": 40.7012141,
        "lng": -73.85596989999999
      },
      "context": [
        "Monday – Saturday, 8am-6pm",
        "Dates: June 6 – 11",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Hammel Playground",
      "address": [
        "84-10 Rockaway Beach Blvd",
        "Queens, NY11693"
      ],
      "coordinates": {
        "lat": 40.5880761,
        "lng": -73.8103386
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Jamaica HRA Center",
      "address": [
        "165-08 88th Avenue",
        "Queens, NY11432"
      ],
      "coordinates": {
        "lat": 40.70859069999999,
        "lng": -73.7962916
      },
      "context": [
        "Monday – Friday, 8AM – 6PM",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "John F. Kennedy Airport",
      "address": [
        "Terminal 8, Red Garage, Level 1, Central Terminal Area",
        "Queens, NY11430"
      ],
      "coordinates": {
        "lat": 40.64167399999999,
        "lng": -73.78790459999999
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "LaGuardia Airport",
      "address": [
        "Terminal B Parking Lot, 1 Central Terminal Dr.",
        "Queens, NY11371"
      ],
      "coordinates": {
        "lat": 40.7728753,
        "lng": -73.87524020000001
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Life Camp INC",
      "address": [
        "111-12 Sutphin Blvd",
        "Queens, NY11435"
      ],
      "coordinates": {
        "lat": 40.6882749,
        "lng": -73.795661
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Make the Road NY – Queens office",
      "address": [
        "92-10 Roosevelt Ave",
        "Queens, NY11372"
      ],
      "coordinates": {
        "lat": 40.7484323,
        "lng": -73.8741462
      },
      "context": [
        "Friday, 12pm-6pm",
        "Dates: June 10",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Murray Playground",
      "address": [
        "11-01 45th Rd",
        "Queens, NY11101"
      ],
      "coordinates": {
        "lat": 40.7472064,
        "lng": -73.9485957
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "NYCHA Ocean Bay",
      "address": [
        "339 Beach 54th Street",
        "Queens, NY11691"
      ],
      "coordinates": {
        "lat": 40.59363949999999,
        "lng": -73.7848461
      },
      "context": [
        "Thursday, 10am-6pm",
        "Dates: June 9",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "NYCHA Pomonok (Pomonok Community Center)",
      "address": [
        "6709 Kissena Blvd",
        "Queens, NY11367"
      ],
      "coordinates": {
        "lat": 40.7349436,
        "lng": -73.8143766
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "NYCHA Woodside",
      "address": [
        "50-37 Newtown Road",
        "Queens, NY11377"
      ],
      "coordinates": {
        "lat": 40.7534226,
        "lng": -73.9104811
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Nautilus Playground (Roy Wilkin’s Park)",
      "address": [
        "175-16 Baisley Blvd",
        "Queens, NY11434"
      ],
      "coordinates": {
        "lat": 40.6841186,
        "lng": -73.7693252
      },
      "context": [
        "Monday – Sunday, 8am-6pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Newtown Playground",
      "address": [
        "92-45 56th Ave",
        "Queens, NY11373"
      ],
      "coordinates": {
        "lat": 40.7370174,
        "lng": -73.86843859999999
      },
      "context": [
        "Sunday, 8am-6pm",
        "Dates: June 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "PS 166",
      "address": [
        "33-09 35 Avenue",
        "Queens, NY11106"
      ],
      "coordinates": {
        "lat": 40.75738610000001,
        "lng": -73.9261081
      },
      "context": [
        "Friday, 10am-6pm",
        "Dates: June 10",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "PS 40Q Health and Wellness Fair",
      "address": [
        "109-20 Union Hall Street",
        "Queens, NY11433"
      ],
      "coordinates": {
        "lat": 40.6940295,
        "lng": -73.7908532
      },
      "context": [
        "Friday, 8am-2pm",
        "Dates: June 10",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Park of the Americas",
      "address": [
        "103-8 42nd Ave",
        "Queens, NY11368"
      ],
      "coordinates": {
        "lat": 40.7484612,
        "lng": -73.8619406
      },
      "context": [
        "Monday – Sunday, 8am-6pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Phil “Scooter” Rizzuto Park",
      "address": [
        "125 95th Avenue",
        "Queens, NY11419"
      ],
      "coordinates": {
        "lat": 40.6947111,
        "lng": -73.8216889
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Queens Museum",
      "address": [
        "126 Flushing Meadows Corona Park Rd",
        "Queens, NY11368"
      ],
      "coordinates": {
        "lat": 40.745914,
        "lng": -73.8467247
      },
      "context": [
        "Wednesday – Sunday, 8am-7pm",
        "Dates: June 8 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Queensbridge Senior Center",
      "address": [
        "10-25 41st Ave",
        "Queens, NY11101"
      ],
      "coordinates": {
        "lat": 40.7555154,
        "lng": -73.9445939
      },
      "context": [
        "Thursday – Friday, 8am-4pm",
        "Dates: June 9 – 10",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Rochdale Village Mall",
      "address": [
        "165-98 Baisley Blvd",
        "Queens, NY11434"
      ],
      "coordinates": {
        "lat": 40.6792638,
        "lng": -73.7778841
      },
      "context": [
        "Monday – Sunday, 8AM-6PM",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Rockaway YMCA",
      "address": [
        "207 Beach 73rd Street",
        "Queens, NY11692"
      ],
      "coordinates": {
        "lat": 40.5894125,
        "lng": -73.8015289
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Rosemary’s Playground",
      "address": [
        "7-15 Woodward Ave",
        "Queens, NY11385"
      ],
      "coordinates": {
        "lat": 40.704639,
        "lng": -73.90480099999999
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Sorrentino Recreation Center",
      "address": [
        "10-12 Beach 19th St",
        "Queens, NY11691"
      ],
      "coordinates": {
        "lat": 40.6025037,
        "lng": -73.7513574
      },
      "context": [
        "Monday – Sunday, 8am-6pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Sts. Joachim and Anne Parish",
      "address": [
        "21827 Hollis Ave",
        "Queens, NY11429"
      ],
      "coordinates": {
        "lat": 40.7115683,
        "lng": -73.73905839999999
      },
      "context": [
        "Wednesday, 9am-7pm, Sunday, 8am-6pm",
        "Dates: June 8",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Travers Park",
      "address": [
        "76-9 34th Ave",
        "Queens, NY11372"
      ],
      "coordinates": {
        "lat": 40.7539534,
        "lng": -73.88936199999999
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Windmuller Park (Lawrence Virgilio Playground)",
      "address": [
        "52-01 39th Dr",
        "Queens, NY11377"
      ],
      "coordinates": {
        "lat": 40.7472935,
        "lng": -73.9107738
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "NYC Health + Hospitals/Elmhurst",
      "address": [
        "77-04 41st Avenue",
        "Elmhurst, New York 11373"
      ],
      "coordinates": {
        "lat": 40.74553179999999,
        "lng": -73.8882282
      },
      "context": [
        "Monday – Friday, 8 a.m. – 4 p.m.",
        "Saturday, 8 a.m. – 12 p.m.",
        "Sunday, Closed",
        "718-334-4000",
        "PCR Testing Offered Here",
        "Walk-ins only at this time.",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "NYC Health + Hospitals/Queens",
      "address": [
        "82-68 164th Street",
        "Jamaica, New York 11432"
      ],
      "coordinates": {
        "lat": 40.7186426,
        "lng": -73.8041807
      },
      "context": [
        "Monday – Friday, 7 a.m. – 6 p.m.",
        "Saturday, 7 a.m. – 4 p.m.",
        "Sunday, Closed",
        "718-883-3000",
        "PCR Testing Offered Here",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "Astoria Library",
      "address": [
        "14-01 Astoria Boulevard",
        "Queens, NY 11102"
      ],
      "coordinates": {
        "lat": 40.7723867,
        "lng": -73.9287274
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm; Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Arverne Library",
      "address": [
        "312 Beach 54th Street",
        "Arverne, NY 11692"
      ],
      "coordinates": {
        "lat": 40.5931209,
        "lng": -73.7840513
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Auburndale Library",
      "address": [
        "25-55 Francis Lewis Boulevard",
        "Queens, NY 11358"
      ],
      "coordinates": {
        "lat": 40.7737652,
        "lng": -73.7963682
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Baisley Park Library",
      "address": [
        "117-11 Sutphin Boulevard",
        "Arverne, NY 11436"
      ],
      "coordinates": {
        "lat": 40.592658,
        "lng": -73.7977928
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, Closed",
        "2 years old and above"
      ]
    },
    {
      "name": "Bayside Library",
      "address": [
        "214-20 Northern Boulevard",
        "Queens, NY 11361"
      ],
      "coordinates": {
        "lat": 40.7601388,
        "lng": -73.7683627
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Bay Terrace Library",
      "address": [
        "18-36 Bell Boulevard",
        "Queens, NY 11360"
      ],
      "coordinates": {
        "lat": 40.782517,
        "lng": -73.777006
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "closed Saturday",
        "2 years old and above"
      ]
    },
    {
      "name": "Bellerose Library",
      "address": [
        "250-6 Hillside Avenue",
        "Queens, NY 11426"
      ],
      "coordinates": {
        "lat": 40.7351203,
        "lng": -73.7171226
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Briarwood Library",
      "address": [
        "85-12 Main Street",
        "Queens, NY 11435"
      ],
      "coordinates": {
        "lat": 40.710049,
        "lng": -73.8194528
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Broad Channel",
      "address": [
        "16-26 Cross Bay Boulevard",
        "Queens, NY 11693"
      ],
      "coordinates": {
        "lat": 40.60074480000001,
        "lng": -73.8200576
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "closed Saturday",
        "2 years old and above"
      ]
    },
    {
      "name": "Broadway Library (mobile bus)",
      "address": [
        "40-20 Broadway",
        "Queens, NY 11103"
      ],
      "coordinates": {
        "lat": 40.7584732,
        "lng": -73.918576
      },
      "context": [
        "Monday 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Cambria Heights Library",
      "address": [
        "218-13 Linden Boulevard",
        "Queens, NY 11411"
      ],
      "coordinates": {
        "lat": 40.695673,
        "lng": -73.7411202
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Corona Library",
      "address": [
        "38-23 104th Street",
        "Queens, NY 11368"
      ],
      "coordinates": {
        "lat": 40.7508971,
        "lng": -73.8619339
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "closed Saturday",
        "2 years old and above"
      ]
    },
    {
      "name": "Douglaston-Little Neck",
      "address": [
        "249-01 Northern Boulevard",
        "Queens, NY 11363"
      ],
      "coordinates": {
        "lat": 40.768206,
        "lng": -73.738255
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "closed Saturday",
        "2 years old and above"
      ]
    },
    {
      "name": "East Elmhurst Library",
      "address": [
        "95-06 Astoria Boulevard",
        "East Elmhurst, NY 11369"
      ],
      "coordinates": {
        "lat": 40.7624374,
        "lng": -73.8738856
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "East Flushing Library",
      "address": [
        "196-36 Northern Boulevard",
        "Queens, NY 11358"
      ],
      "coordinates": {
        "lat": 40.7576286,
        "lng": -73.7843212
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Elmhurst Library",
      "address": [
        "86-07 Broadway",
        "Queens, NY 11373"
      ],
      "coordinates": {
        "lat": 40.738428,
        "lng": -73.87710009999999
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Far Rockaway Library",
      "address": [
        "1003 Beach 20th Street",
        "Far Rockaway, NY 11691"
      ],
      "coordinates": {
        "lat": 40.6025325,
        "lng": -73.7535059
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Forest Hills Library",
      "address": [
        "108-19 71st Avenue",
        "Queens, NY 11375"
      ],
      "coordinates": {
        "lat": 40.722099,
        "lng": -73.84300499999999
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Fresh Meadows Library",
      "address": [
        "193-20 Horace Harding Parkway",
        "Queens, NY 11365"
      ],
      "coordinates": {
        "lat": 40.741528,
        "lng": -73.782242
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Flushing Library",
      "address": [
        "41-17 Main Street",
        "Queens, NY 11355"
      ],
      "coordinates": {
        "lat": 40.75779600000001,
        "lng": -73.8289109
      },
      "context": [
        "Monday-Thursday, 10am-8pm",
        "Friday, 10am-6pm",
        "Saturday, 10am-5pm",
        "Sunday, 12pm-5pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Flushing Town Hall",
      "address": [
        "137-35 Northern Boulevard",
        "Queens, NY 11374"
      ],
      "coordinates": {
        "lat": 40.7637342,
        "lng": -73.8299598
      },
      "context": [
        "Wednesday-Friday, 12pm-5pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Glen Oaks Library",
      "address": [
        "256-04 Union Turnpike",
        "Queens, NY 11004"
      ],
      "coordinates": {
        "lat": 40.7451357,
        "lng": -73.7148935
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Glendale Library",
      "address": [
        "78-60 73rd Place",
        "Queens, NY 11385"
      ],
      "coordinates": {
        "lat": 40.7026076,
        "lng": -73.8758598
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Closed Saturdays",
        "2 years old and above"
      ]
    },
    {
      "name": "Hillcrest Library",
      "address": [
        "187-05 Union Turnpike",
        "Queens, NY 11366"
      ],
      "coordinates": {
        "lat": 40.7292614,
        "lng": -73.7819371
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Hillcrest Senior Center",
      "address": [
        "91-34 182 Place",
        "Queens, NY 11423"
      ],
      "coordinates": {
        "lat": 40.7092343,
        "lng": -73.778395
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Monday-Thursday, 9am-5pm",
        "Dates: May 16 – 19",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Hollis Library",
      "address": [
        "202-05 Hillside Avenue",
        "Queens, NY 11423"
      ],
      "coordinates": {
        "lat": 40.72015529999999,
        "lng": -73.7622375
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm; Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Howard Beach Library",
      "address": [
        "92-06 156th Avenue",
        "Queens, NY 11414"
      ],
      "coordinates": {
        "lat": 40.6638051,
        "lng": -73.8418604
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "closed Saturday",
        "2 years old and above"
      ]
    },
    {
      "name": "Hunters Point Library",
      "address": [
        "47-40 Center Boulevard",
        "Queens, NY 11109"
      ],
      "coordinates": {
        "lat": 40.7453222,
        "lng": -73.9580667
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Jackson Heights Library",
      "address": [
        "35-5 81st Street",
        "Queens, NY 11372"
      ],
      "coordinates": {
        "lat": 40.7502064,
        "lng": -73.8850497
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, Closed",
        "2 years old and above"
      ]
    },
    {
      "name": "Kew Gardens Hills Library",
      "address": [
        "72-33 Vleigh Place",
        "Flushing, NY 11367"
      ],
      "coordinates": {
        "lat": 40.7259236,
        "lng": -73.8207254
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Sunday 12pm-4pm; closed Saturday",
        "2 years old and above"
      ]
    },
    {
      "name": "Langston Hughes Library",
      "address": [
        "100-01 Northern Boulevard",
        "Queens, NY 11368"
      ],
      "coordinates": {
        "lat": 40.757643,
        "lng": -73.868157
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Laurelton Library",
      "address": [
        "134-26 225th Street",
        "Queens, NY 11413"
      ],
      "coordinates": {
        "lat": 40.6769537,
        "lng": -73.7457171
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Lefferts Library",
      "address": [
        "103-34 Lefferts Boulevard",
        "Queens, NY 11419"
      ],
      "coordinates": {
        "lat": 40.6869293,
        "lng": -73.8248259
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm; Closed Saturdays",
        "2 years old and above"
      ]
    },
    {
      "name": "Lefrak City Library (mobile bus)",
      "address": [
        "98-30 57th Avenue",
        "Queens, NY 11368"
      ],
      "coordinates": {
        "lat": 40.7375514,
        "lng": -73.8609949
      },
      "context": [
        "Friday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Long Island City Library",
      "address": [
        "37-44 21st Street",
        "Long Island City, NY 11101"
      ],
      "coordinates": {
        "lat": 40.7576767,
        "lng": -73.9392185
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm; Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Maspeth Library",
      "address": [
        "69-70 Grand Avenue",
        "Queens, NY 11378"
      ],
      "coordinates": {
        "lat": 40.727056,
        "lng": -73.89318759999999
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "McGoldrick Library",
      "address": [
        "155-06 Roosevelt Avenue",
        "Flushing, NY 11354"
      ],
      "coordinates": {
        "lat": 40.7638667,
        "lng": -73.8098213
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm; Thursday, 12pm-7pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Middle Village Library",
      "address": [
        "72-31 Metropolitan Avenue",
        "Queens, NY 11379"
      ],
      "coordinates": {
        "lat": 40.7129576,
        "lng": -73.880911
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "closed Saturday",
        "2 years old and above"
      ]
    },
    {
      "name": "Mitchell-Linden Library",
      "address": [
        "31-32 Union Street",
        "Queens, NY 11354"
      ],
      "coordinates": {
        "lat": 40.7691277,
        "lng": -73.82688449999999
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "North Forest Park Library",
      "address": [
        "98-27 Metropolitan Avenue",
        "Queens, NY 11375"
      ],
      "coordinates": {
        "lat": 40.7111214,
        "lng": -73.8535999
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "closed Saturday",
        "2 years old and above"
      ]
    },
    {
      "name": "North Hills Library",
      "address": [
        "57-04 Marathon Parkway",
        "Queens, NY 11362"
      ],
      "coordinates": {
        "lat": 40.7592519,
        "lng": -73.73181090000001
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "closed Saturday",
        "2 years old and above"
      ]
    },
    {
      "name": "Peninsula Library",
      "address": [
        "92-25 Rockaway Beach Boulevard",
        "Rockaway Beach, NY 11693"
      ],
      "coordinates": {
        "lat": 40.58585799999999,
        "lng": -73.81609499999999
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm; Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Pomonok Library",
      "address": [
        "158-21 Jewel Avenue",
        "Queens, NY 11365"
      ],
      "coordinates": {
        "lat": 40.7326235,
        "lng": -73.81010669999999
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "closed Saturday",
        "2 years old and above"
      ]
    },
    {
      "name": "Poppenhusen Library",
      "address": [
        "121-23 14th Avenue",
        "Queens, NY 11356"
      ],
      "coordinates": {
        "lat": 40.78623930000001,
        "lng": -73.84598299999999
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "closed Saturday",
        "2 years old and above"
      ]
    },
    {
      "name": "Queens Botanical Garden",
      "address": [
        "43-50 Main Street",
        "Queens, NY 11355"
      ],
      "coordinates": {
        "lat": 40.75123929999999,
        "lng": -73.8261905
      },
      "context": [
        "Tuesday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Queens Central Library",
      "address": [
        "89-11 Merrick Blvd.",
        "Jamaica NY 11432"
      ],
      "coordinates": {
        "lat": 40.70769689999999,
        "lng": -73.795074
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm;",
        "Sunday 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Queens Theatre",
      "address": [
        "14 United Nations Avenue South, Flushing Meadows Corona Park",
        "Queens, NY 11368"
      ],
      "coordinates": {
        "lat": 40.744272,
        "lng": -73.8443904
      },
      "context": [
        "Tuesday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Queens Village",
      "address": [
        "94-11 217th Street",
        "Queens, NY 11428"
      ],
      "coordinates": {
        "lat": 40.7199144,
        "lng": -73.7390056
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm; Thursday, 12pm-7pm; closed Saturday",
        "2 years old and above"
      ]
    },
    {
      "name": "Queensbridge Tech Lab Library",
      "address": [
        "10-43 41st Avenue",
        "Queens, NY 11101"
      ],
      "coordinates": {
        "lat": 40.755016,
        "lng": -73.9441662
      },
      "context": [
        "Monday, Wednesday, and Thursday 12pm-7pm;",
        "Saturday 1pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Queensboro Hill",
      "address": [
        "60-05 Main Street",
        "Queens, NY 11355"
      ],
      "coordinates": {
        "lat": 40.74382019999999,
        "lng": -73.8256849
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Rego Park Library",
      "address": [
        "91-41 63rd Drive",
        "Rego Park, NY 11374"
      ],
      "coordinates": {
        "lat": 40.727299,
        "lng": -73.86448299999999
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Ridgewood Library",
      "address": [
        "20-12 Madison Street",
        "Queens, NY 11385"
      ],
      "coordinates": {
        "lat": 40.70517419999999,
        "lng": -73.9025802
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Richmond Hill Library",
      "address": [
        "118-14 Hillside Avenue",
        "Richmond Hill, NY 11418"
      ],
      "coordinates": {
        "lat": 40.7008206,
        "lng": -73.8316579
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Rosedale Library",
      "address": [
        "144-20 243rd Street",
        "Queens, NY 11422"
      ],
      "coordinates": {
        "lat": 40.6599872,
        "lng": -73.73992849999999
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "closed Saturday",
        "2 years old and above"
      ]
    },
    {
      "name": "Seaside Library",
      "address": [
        "116-15 Rockaway Beach Boulevard",
        "Queens, NY 11694"
      ],
      "coordinates": {
        "lat": 40.5793817,
        "lng": -73.8377405
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "closed Saturday",
        "2 years old and above"
      ]
    },
    {
      "name": "South Hollis Library",
      "address": [
        "204-01 Hollis Avenue",
        "Queens, NY 11412"
      ],
      "coordinates": {
        "lat": 40.7070094,
        "lng": -73.7538162
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "closed Saturday",
        "2 years old and above"
      ]
    },
    {
      "name": "South Jamaica Library",
      "address": [
        "108-41 Guy R. Brewer Boulevard",
        "Queens, NY 11433"
      ],
      "coordinates": {
        "lat": 40.6956931,
        "lng": -73.79010720000001
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm; Thursday, 12pm-7pm; closed Saturday",
        "2 years old and above"
      ]
    },
    {
      "name": "St. Albans Library",
      "address": [
        "191-05 Linden Boulevard",
        "Queens, NY 11412"
      ],
      "coordinates": {
        "lat": 40.6924387,
        "lng": -73.76002799999999
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Sunnyside Library",
      "address": [
        "43-06 Greenpoint Avenue",
        "Queens, NY 11104"
      ],
      "coordinates": {
        "lat": 40.7408115,
        "lng": -73.9216774
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Whitestone Library",
      "address": [
        "151-10 14th Road",
        "Queens, NY 11357"
      ],
      "coordinates": {
        "lat": 40.7885296,
        "lng": -73.8107167
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "Saturday, 10am-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Windsor Park Library",
      "address": [
        "79-50 Bell Boulevard",
        "Queens, NY 11364"
      ],
      "coordinates": {
        "lat": 40.7344759,
        "lng": -73.75570859999999
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "closed Saturday",
        "2 years old and above"
      ]
    },
    {
      "name": "Woodhaven Library",
      "address": [
        "85-41 Forest Parkway",
        "Queens, NY 11421"
      ],
      "coordinates": {
        "lat": 40.69454100000001,
        "lng": -73.8614468
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "closed Saturday",
        "2 years old and above"
      ]
    },
    {
      "name": "Woodside Library",
      "address": [
        "54-22 Skillman Avenue",
        "Queens, NY 11377"
      ],
      "coordinates": {
        "lat": 40.745331,
        "lng": -73.909791
      },
      "context": [
        "Monday, Wednesday, & Friday, 10am-5pm;",
        "Tuesday, 1pm-5pm;",
        "Thursday, 12pm-7pm;",
        "closed Saturday",
        "2 years old and above"
      ]
    },
    {
      "name": "Forest Ave",
      "address": [
        "1351 Forest Ave",
        "Staten Island, NY10302"
      ],
      "coordinates": {
        "lat": 40.6262299,
        "lng": -74.1355571
      },
      "context": [
        "Monday – Sunday, 8:00AM-6:00PM",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Hylan Plaza Shopping Center",
      "address": [
        "2656 Hylan Blvd",
        "Staten Island, NY10306"
      ],
      "coordinates": {
        "lat": 40.5464584,
        "lng": -74.1414633
      },
      "context": [
        "Monday – Sunday, 8:00AM-6:00PM",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Brookfield Park",
      "address": [
        "575 Arthur Kill Rd",
        "Staten Island, NY10308"
      ],
      "coordinates": {
        "lat": 40.563713,
        "lng": -74.16279399999999
      },
      "context": [
        "Thursday – Sunday, 8am-7pm",
        "Dates: June 9 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Cassidy Coles Senior Center",
      "address": [
        "125 Cassidy Pl",
        "Staten Island, NY10301"
      ],
      "coordinates": {
        "lat": 40.6422563,
        "lng": -74.0964201
      },
      "context": [
        "Monday – Friday, 8am-4pm",
        "Dates: June 6 – 10",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Conference House Park",
      "address": [
        "7421 Hylan Blvd",
        "Staten Island, NY10307"
      ],
      "coordinates": {
        "lat": 40.5005834,
        "lng": -74.2512474
      },
      "context": [
        "Monday – Wednesday, 8am-7pm",
        "Dates: June 6 – 8",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "NYC Health + Hospitals Seaview",
      "address": [
        "460 Brielle Ave",
        "Staten Island, NY10314"
      ],
      "coordinates": {
        "lat": 40.5914151,
        "lng": -74.132324
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "NYCHA Mariners Harbor",
      "address": [
        "245 Continental Pl",
        "Staten Island, NY10303"
      ],
      "coordinates": {
        "lat": 40.63029179999999,
        "lng": -74.16522850000001
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "NYCHA Richmond Terrace",
      "address": [
        "71 Jersey St",
        "Staten Island, NY10301"
      ],
      "coordinates": {
        "lat": 40.64587059999999,
        "lng": -74.0878857
      },
      "context": [
        "Monday – Tuesday, 8am-7pm",
        "Dates: June 6 – 7",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Port Richmond High School",
      "address": [
        "85 St. Joseph’s Avenue",
        "Staten Island, NY10302"
      ],
      "coordinates": {
        "lat": 40.634533,
        "lng": -74.142485
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "Wolfe’s Pond Park (Parking Lot)",
      "address": [
        "Wolfe’s Pond Park Parking Lot, Chester St",
        "Staten Island, NY10312"
      ],
      "coordinates": {
        "lat": 40.51755929999999,
        "lng": -74.1900464
      },
      "context": [
        "Monday – Sunday, 8am-7pm",
        "Dates: June 6 – 12",
        "PCR and Rapid Antigen Testing Available Here",
        "4 years old and above"
      ]
    },
    {
      "name": "NYC Health + Hospitals/Gotham Health, Vanderbilt",
      "address": [
        "165 Vanderbilt Avenue",
        "Staten Island, NY 10304"
      ],
      "coordinates": {
        "lat": 40.6203745,
        "lng": -74.0768895
      },
      "context": [
        "Monday – Friday, 7 a.m. – 4 p.m.",
        "844-NYC-4NYC",
        "PCR Testing Offered Here",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "Former St John Villa HS",
      "address": [
        "57 Cleveland Place",
        "Staten Island, NY 10305"
      ],
      "coordinates": {
        "lat": 40.6012163,
        "lng": -74.0687077
      },
      "context": [
        "Monday – Sunday, 9 a.m. – 7 p.m.",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "Staten Island Ferry",
      "address": [
        "Terminal 1 Bay St, Retail Space 103 (Level 1 of the Ferry Terminal, next to the North Shore Esplanade exit)",
        "Staten Island, NY 10301"
      ],
      "coordinates": {
        "lat": 40.6420188,
        "lng": -74.07507559999999
      },
      "context": [
        "Monday – Sunday, 9 a.m. – 7 p.m.",
        "Rapid Molecular Testing Available Here",
        "Pre-register for your visit"
      ]
    },
    {
      "name": "Snug Harbor Cultural Center & Botanical Garden",
      "address": [
        "1000 Richmond Terrace, Building P",
        "Staten Island, NY 10301"
      ],
      "coordinates": {
        "lat": 40.6418723,
        "lng": -74.1019362
      },
      "context": [
        "Monday-Friday, 2pm-3pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Staten Island Chidren’s Museum",
      "address": [
        "1000 Richmond Terrace, Building M",
        "Staten Island, NY 10301"
      ],
      "coordinates": {
        "lat": 40.6445604,
        "lng": -74.10275279999999
      },
      "context": [
        "Saturday-Sunday, 10am-1pm & 2pm-5pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Staten Island Zoo",
      "address": [
        "614 Broadway",
        "Staten Island, NY 10301"
      ],
      "coordinates": {
        "lat": 40.625124,
        "lng": -74.11537
      },
      "context": [
        "Monday-Sunday, 10:30am-3:30pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Charleston Library",
      "address": [
        "225 Bricktown Way",
        "Staten Island, NY 10309"
      ],
      "coordinates": {
        "lat": 40.5310545,
        "lng": -74.2322803
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Douglas Hills Library",
      "address": [
        "1617 Richmond Road",
        "Staten Island, NY 10304"
      ],
      "coordinates": {
        "lat": 40.5903812,
        "lng": -74.10110089999999
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Great Kills Library",
      "address": [
        "56 Giffords Lane",
        "Staten Island, NY 10308"
      ],
      "coordinates": {
        "lat": 40.5524219,
        "lng": -74.15113149999999
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Greenbelt Recreation Center",
      "address": [
        "501 Brielle Avenue",
        "Staten Island, NY 10314"
      ],
      "coordinates": {
        "lat": 40.5918312,
        "lng": -74.13919899999999
      },
      "context": [
        "Monday-Friday, 6:30am-8:30pm",
        "Saturday-Sunday, 8am-3pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Hugenot Park Library",
      "address": [
        "830 Hugenot Avenue",
        "Staten Island, NY 10312"
      ],
      "coordinates": {
        "lat": 40.5340699,
        "lng": -74.19320309999999
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "New Dorp Library",
      "address": [
        "309 New Dorp Lane",
        "Staten Island, NY 10306"
      ],
      "coordinates": {
        "lat": 40.5718907,
        "lng": -74.111975
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "South Beach Library",
      "address": [
        "21-25 Robin Road",
        "Staten Island, NY 10305"
      ],
      "coordinates": {
        "lat": 40.5957922,
        "lng": -74.0630237
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Stapleton Library",
      "address": [
        "132 Canal Street",
        "Staten Island, NY 10301"
      ],
      "coordinates": {
        "lat": 40.6262563,
        "lng": -74.07782929999999
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Todt-Hill Westerleigh",
      "address": [
        "2550 Victory Boulevard",
        "Staten Island, NY 10314"
      ],
      "coordinates": {
        "lat": 40.6095905,
        "lng": -74.1485397
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "Tottenville",
      "address": [
        "7430 Amboy Road",
        "Staten Island, NY 10307"
      ],
      "coordinates": {
        "lat": 40.5095196,
        "lng": -74.24413729999999
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    },
    {
      "name": "West New Brighton",
      "address": [
        "976 Castleton Avenue",
        "Staten Island, NY 10310"
      ],
      "coordinates": {
        "lat": 40.6345205,
        "lng": -74.1148429
      },
      "context": [
        "Monday-Friday, 12pm-4pm",
        "2 years old and above"
      ]
    }
  ]
}